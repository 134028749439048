exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-it-consulting-1-jsx": () => import("./../../../src/pages/services/it-consulting-1.jsx" /* webpackChunkName: "component---src-pages-services-it-consulting-1-jsx" */),
  "component---src-pages-services-mobile-app-developement-1-js": () => import("./../../../src/pages/services/mobile-app-developement-1.js" /* webpackChunkName: "component---src-pages-services-mobile-app-developement-1-js" */),
  "component---src-pages-services-web-app-development-1-js": () => import("./../../../src/pages/services/web-app-development-1.js" /* webpackChunkName: "component---src-pages-services-web-app-development-1-js" */),
  "component---src-pages-services-website-development-1-js": () => import("./../../../src/pages/services/website-development-1.js" /* webpackChunkName: "component---src-pages-services-website-development-1-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

